import Mainvideo from './components/video.mp4'

function App() {
  return (
    <div className="h-screen w-full">
      <video autoPlay playsInline loop muted
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: -1
        }}>
        <source src={Mainvideo} type='video/mp4' ></source>
      </video>
    </div>
  );
}

export default App;
